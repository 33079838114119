import(/* webpackMode: "eager" */ "/vercel/path0/components/component-preview.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/component-source.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/copy-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/tally-embed.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.24.0_@opentelemetry+api@1.4.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.24.0_@opentelemetry+api@1.4.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.24.0_@opentelemetry+api@1.4.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/icons/icons.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-container.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/theme.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-header.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/skeleton.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-actions-copy.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-actions.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-in-reply-to.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-info-created-at.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-link.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-body.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-container.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-info.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-media-video.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/verified-badge.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-header.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-media.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-replies.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-body.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-not-found.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-skeleton.module.css")